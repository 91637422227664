import React, { Fragment } from 'react';
import * as styles from './index.styles';
import { ButtonHeight, ButtonDesign, ButtonType, ButtonDisplay } from './index.type';
import { MouseEventHandler } from 'react';
import LoadingSpinner from '~/assets/animation/loading-spinner.svg';
import Link from 'next/link';
import { FontSizeName } from '~/styles/font';

export type Props = {
  fontSize: FontSizeName;
  mobileFontSize?: FontSizeName;
  buttonHeight: ButtonHeight;
  mobileButtonHeight?: ButtonHeight;
  design?: ButtonDesign;
  children: React.ReactNode;
  display?: ButtonDisplay;
  type?: ButtonType;
  iconSrc?: string;
  iconAlt?: string;
  onClick?: MouseEventHandler;
  tabIndex?: number;
  disable?: boolean;
  round?: boolean;
  testId?: string;
  loading?: boolean;
  id?: string;
  nextLink?: string;
  href?: string;
  openInNewTab?: boolean;
  minWidth?: number;
  bold?: boolean;
};

export const MESSAGE = {
  LOADING: 'ロード中',
} as const;

export const Button: React.FC<Props> = (props: Props) => {
  const content = (
    <Fragment>
      {props.loading ? (
        <img src={LoadingSpinner} alt={MESSAGE.LOADING} css={styles.loadingIcon} />
      ) : (
        <span css={styles.content.container}>
          {props.iconSrc && (
            <div
              css={styles.content.iconContainer({
                height: props.buttonHeight,
                mobileHeight: props.mobileButtonHeight,
                design: props.design || 'default',
              })}
            >
              <img src={props.iconSrc} css={styles.content.icon} alt={props.iconAlt} />
            </div>
          )}
          <span
            css={styles.content.text({
              height: props.buttonHeight,
              mobileHeight: props.mobileButtonHeight,
              design: props.design || 'default',
            })}
          >
            {props.children}
          </span>
        </span>
      )}
    </Fragment>
  );

  const commonProps = {
    'data-testid': props.testId,
    tabIndex: props.tabIndex,
    id: props.id,
    css: [
      styles.design[props.design || 'default'],
      styles.base(props.buttonHeight, props.fontSize, {
        minWidth: props.minWidth,
        mobileButtonHeight: props.mobileButtonHeight,
        mobileFontSize: props.mobileFontSize,
        bold: props.bold,
      }),
      props.disable ? styles.disable : null,
      styles.displayType(props.display || 'inline'),
      props.round ? styles.round : null,
      props.loading ? styles.loading : null,
      props.href ? styles.anchor(props.buttonHeight) : null,
      styles.padding(props.fontSize),
    ],
  };

  const working = !(props.disable || props.loading);

  if (!props.nextLink && props.href) {
    return (
      <a
        {...commonProps}
        href={props.href}
        {...(props.openInNewTab
          ? {
              target: '_blank',
              rel: 'noopener noreferrer',
            }
          : {})}
      >
        {content}
      </a>
    );
  }

  const button = (
    <button
      {...commonProps}
      disabled={props.disable}
      type={props.type || 'button'}
      onClick={working ? props.onClick : () => {}}
    >
      {content}
    </button>
  );

  return working && props.nextLink ? <Link href={props.nextLink}>{button}</Link> : button;
};
